.exip-p {
    background: url('../img/sale-popup/bg.png') 0 0 repeat transparent;
    color: #202020;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: -1px;
    width: 100%;
    z-index: 10;
    display: none;
}
.exip-p-bg {
    background: url('../img/sale-popup/body.png');
    margin: 50px auto 0;
    padding: 2px 4px 6px;
    height: 350px;
    width: 450px;
    overflow: hidden;
    text-align: center;
    position: relative;
}

.exip-p-bg .close {
    background: url("../img/sale-popup/close-btn.png") 0px 0px no-repeat;
    width: 11px;
    height: 11px;
    position: absolute;
    top: 22px;
    right: 24px;
}

.exip-p-badge {
    background: url('../img/sale-popup/badge.png');
    margin: 36px auto 0;
    padding: 0 7px;
    height: 80px;
    width: 314px;
    overflow: hidden;
    text-align: center;
    position: relative;

    color: #fff;
    font: bold 46px/76px Arial;
    text-align: center;
}
.exip-p-text {
    color: #fff;
    font: normal 16px/30px Arial;
}
.exip-p-timer-el {
    background: url('../img/sale-popup/timer-bg.png') 0 0 no-repeat transparent;
    display: inline-block;
    height: 91px;
    width: 86px;
    text-align: center;
    margin: 10px 10px 0;
}
.exip-p-timer-el .minutes,
.exip-p-timer-el .seconds {
    color: #000;
    font: bold 54px/86px Arial;
}
.exip-p-timer-el .units {
    color: #000;
    font: bold 14px/16px Arial;
    margin-top: -20px;
}
.exip-p-btn {
    background: url("../img/sale-popup/btn.png") 0 0 no-repeat transparent;
    width: 334px;
    height: 56px;
    color: white;
    font: bold 23px/56px Arial, sans-serif;
    font-weight: bold;
    display: block;
    margin: 15px auto 0;
    text-decoration: none;
    text-align: left;
    padding-left: 50px;
    outline: none;
}
.exip-p-btn:hover {
    background-position: 0 -60px;
    color: #1f3d1c;
}
.exip-p-btn.disabled,
.exip-p-btn.disabled:hover {
    background-position: 0 -120px;
    color: #656565;
    cursor: default;
}

.lang-it .exip-p-btn {
    font-size: 16px;
}
.lang-pt .exip-p-btn {
    font-size: 16px;
}